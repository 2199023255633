import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const basePath = process.env.REACT_APP_BASE_PATH || "/";

const LazyHome = lazy(() => import("./pages/HomePage/HomePage.jsx"));
const LazyRules1 = lazy(() => import("./pages/Page1/Page1.jsx"));
const LazyRules2 = lazy(() => import("./pages/Page2/Page2.jsx"));
const LazyRules3 = lazy(() => import("./pages/Page3/Page3.jsx"));
const LazyRules4 = lazy(() => import("./pages/Page4/Page4.jsx"));

const App = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<LazyHome />} />
          <Route path="/consultation" element={<LazyRules1 />} />
          <Route path="/rules" element={<LazyRules2 />} />
          <Route path="/bloodTest" element={<LazyRules3 />} />
          <Route path="/bloodDiabetes" element={<LazyRules4 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
